<template>
  <div class="row" ref="userList">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Deleted Users List') }}</h4>
        </div>

        <div>
          <general-data-table
            ref="table"
            :api-url="'users/getDeletedUsers'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              :label="Actions">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/haveOneOfPermissions'](['users/restore-user'])"
                   v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Restore')"
                   class="btn-danger btn-simple btn-link"
                   @click="openConfirmModal(scope.row.id)"><i class="fas fa-trash-restore fa-lg"></i></a>
              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <confirmation-modal :visible="confirmModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to Restore this user?')"
                    @close="closeConfirmModal()"
                    @confirm="confirmModal()">
      </confirmation-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {ConfirmationModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";

export default {
  components: {
    NLPagination,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    ConfirmationModal
  },

  data() {

    return {
      tableColumns: [],
      fixedColumn: null,
      loader: {},
      confirmModalVisibility: false,
      toRestoreId: undefined,

    }

  },
  created(){
    this.responsiveViewPort();
  },
  methods: {
    responsiveViewPort(){
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.fixedColumn = null;
        this.tableColumns = [
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Full Name'), value: 'full_name', minWidth: '200', align: 'center'},
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Role'), value: 'role.name', minWidth: '200', align: 'center'},
        ];
      }else{
        this.fixedColumn = 'right';
        this.tableColumns = [
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Full Name'), value: 'full_name', minWidth: '200', align: 'center'},
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Email'), value: 'email', minWidth: '200', align: 'center'},
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Mobile'), value: 'mobile', minWidth: '200', align: 'center'},
          {label: this.$store.getters['languageMixin/getStringFromLanguage']('Role'), value: 'role.name', minWidth: '200', align: 'center'},
        ];
      }
    },
    openConfirmModal(id) {
      this.confirmModalVisibility = true;
      this.toRestoreId =id;
    },
    confirmModal() {
      this.axios.post("users/restore-user/" + this.toRestoreId, {headers: {}}).then((response) => {
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("User Restored successfully"),
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeConfirmModal();
      this.toDeleteId = undefined;
    },

    closeConfirmModal() {
      this.confirmModalVisibility = false;
    },
  },

}
</script>
<style>
</style>
